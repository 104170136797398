<template>
  <div>

    <div class="col mx-0 px-0">
      <div class="d-flex justify-content-end">
        <!--         v-if="items.length > 0"-->
        <div
          class="d-flex align-items-center justify-content-end"
          align-self="end"
          style="text-align: end"
        >
          <b-dropdown
            boundary="viewport"
            class="w-100 mb-1"
            style="z-index: 13"
            variant="outline-secondary"
            text="Split Link"
            checkbox-menu
            allow-focus
            dropdown
          >
            <template #button-content>
              <span> <uil-columns /> Columns </span>
            </template>
            <b-dropdown-form class="w-100">
              <b-form-group>
                <template #label>
                  {{ baseSpecSelectedAll }}
                  <b-form-checkbox
                    v-model="allSelected"
                    aria-describedby="flavours"
                    aria-controls="flavours"
                    @change="toggleAllDrop"
                  >
                    {{ allSelected ? 'Deselect All' : 'Select All' }}
                  </b-form-checkbox>
                  <b-dropdown-divider style="margin-top:3px" />
                </template>
                <b-form-checkbox
                  v-for="(f, index) in fields"
                  :key="'check' + index"
                  v-model="displayColumnsCheck"
                  :value="f.key"
                >{{ f.label }}
                </b-form-checkbox>
              </b-form-group>
              <b-dropdown-divider />
              <div style="display: flex; align-items: end; justify-content: end">
                <b-button
                  class="py-0"
                  variant="outline-light"
                  style="border-color: transparent; color:  rgba(59, 171, 255, 1) "
                  @click="resetCols()"
                >Reset
                </b-button>
              </div>
            </b-dropdown-form>
          </b-dropdown>
        </div>
      </div>
    </div>
    <div
      v-for="sorted,index in sortedSummary"
      :key="index"
      class="mb-5"
    >
      <div class="row">
        <div class="col">
          <h4>{{ sorted.level_2_name }}</h4>
        </div>
      </div><GroupedTable
        :items="sorted.items"
        :filter_columns="filter_columns"
        :column_headers="table_fields"
        :index_table="index+''+sorted.level_2_key"
        :colums-check="fields"
        :display_columns_check="displayColumnsCheck"
        @swapItem="handleSwapClick"
      />
    </div>
  </div>
</template>
<script>
import {
  BButton, BDropdown, BDropdownDivider, BDropdownForm, BFormCheckbox, BFormGroup,
} from 'bootstrap-vue'
import { UilColumns } from '@iconscout/vue-unicons'
import GroupedTable from '@/views/Quotes/Components/Utils/GroupedTable.vue'
import uniformatForTable from '@/assets/objects/uniformat_for_table_grouping.json'

export default {
  name: 'UtilityTableGrouping',
  components: {
    BDropdownDivider,
    BFormGroup,
    BDropdown,
    BFormCheckbox,
    BButton,
    BDropdownForm,
    UilColumns,
    GroupedTable,
  },
  props: {
    table_content: {
      type: Array,
    },
    table_fields: {
      type: Array,
    },
    display_columns_check: {
      type: Array,
      default: null,
    },
    filter_columns: {
      type: Boolean,
      default: false,
    },
    colCheckFields: {
      type: Array,
      default: null,
    },
    selectAllTotal:{
      type: Number,
      default: 7,
    }
  },
  data() {
    return {
      uniformatHierarchy: uniformatForTable,
      groupedSummary: [], // Resulting grouped data
      sortedSummary: [], // Sorted summary
      allSelected: false,
      displayColumnsCheck: this.display_columns_check,
      fields: this.colCheckFields,
      default_columns: [],
    }
  },
  computed: {
    baseSpecSelectedAll() {
      if (this.displayColumnsCheck.length >= this.selectAllTotal) {
        this.allSelected = true
      } else {
        this.allSelected = false
      }
    },
  },
  mounted() {
    this.groupAndOrderData()
    this.default_columns = this.display_columns_check
  },
  methods: {
    groupAndOrderData() {
      const grouped = []
      this.table_content.forEach(specQuote => {
        const uniformat = this.uniformatHierarchy[specQuote.uniformat]
        if (uniformat) {
          const level1Key = uniformat.level_1_key
          const level2Key = uniformat.level_2_key
          const categoryName = uniformat.level_1_name
          const subCategoryName = uniformat.level_2_name

          // Calculate other profit total
          specQuote.other_profit_total = specQuote.construction_work_id === 69
            ? specQuote.total_price - specQuote.total_cost
            : 0

          // Find existing group
          const existingIndex = grouped.findIndex(
            item => item.category_name === categoryName
                  && item.level_2_name === subCategoryName,
          )

          if (existingIndex !== -1) {
            grouped[existingIndex].items.push(specQuote)
          } else {
            grouped.push({
              key: level1Key,
              level_2_key: level2Key,
              category_name: categoryName,
              level_2_name: subCategoryName,
              items: [specQuote],
            })
          }
        }
      })
      // Sort the grouped data
      this.sortedSummary = grouped.sort((a, b) => {
        const regex = /^([A-Z])-?(\d+)/
        const extractSortKey = key => {
          const match = key.match(regex)
          const prefix = match ? match[1] : ''
          const number = match && match[2] ? parseInt(match[2], 10) : 0
          return { prefix, number }
        }

        const aKey = extractSortKey(a.level_2_key)
        const bKey = extractSortKey(b.level_2_key)

        if (aKey.prefix === bKey.prefix) {
          return aKey.number - bKey.number
        }
        return aKey.prefix.localeCompare(bKey.prefix)
      })
    },
    handleSwapClick(item) {
      this.$emit('clickSwapAction', item)
    },
    // display columns logic
    resetCols() {
      this.displayColumnsCheck = this.default_columns
    },
    toggleAllDrop(checked) {
      if (checked) {
        this.displayColumnsCheck = ['name']
        if (this.fields.length > 0) {
          this.fields.map(f => {
            this.displayColumnsCheck.push(f.key)
          })
        }
      } else {
        this.displayColumnsCheck = ['name']
      }
    },
  },
}
</script>
<style scoped lang="scss">

</style>
